import { useTranslation } from 'react-i18next';
import styles from './StoreRating.module.scss';

export default function StoreRating() {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.ratingWrapper}>
        <div className={styles.stars}></div>
        <span>{t('global.rating', { stars: (4.7).toLocaleString() })}</span>
      </div>
      <div className={styles.extraInfo}>
        {t('global.over')} <strong>100 000</strong> {t('global.downloads')}
      </div>
    </div>
  );
}
