import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const HttpApi = new Backend();
HttpApi.init(null, { loadPath: `locales/{{lng}}/{{ns}}.json?v=${new Date().getTime()}` });

// Options docs: https://www.i18next.com/overview/configuration-options
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem('lang') || 'sv',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for React as it escapes by default
    }
  });

export default i18n;
