import { useTranslation } from 'react-i18next';
import useScreenTracking from 'hooks/useScreenTracking';
import { TEST_ID } from '../../constants';

export default function PhysicalStatsQuestion({ onNextStep, onUpdate, height, weight, age }) {
  useScreenTracking('PhysicalStats');
  const { t } = useTranslation();

  const submit = (e) => {
    e.preventDefault();
    onNextStep();
  };

  const disableButton = TEST_ID ? !height || !weight : !height || !weight || !age;

  return (
    <>
      <div className="question-header">
        <small>{t('questionnaire.count', { num: 6, total: 6 })}</small>
        <h2>{t('questionnaire.question.physical_stats.heading')}</h2>
      </div>
      <p>{t('questionnaire.question.physical_stats.sub_heading')}</p>
      <form onSubmit={submit}>
        <label htmlFor="weight">{t('questionnaire.question.physical_stats.weight')}</label>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          id="weight"
          onChange={onUpdate('weight')}
          value={weight}
          autoComplete="off"
        />
        <label htmlFor="height">{t('questionnaire.question.physical_stats.height')}</label>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          id="height"
          onChange={onUpdate('height')}
          value={height}
          autoComplete="off"
        />
        {!TEST_ID ? (
          <>
            <label htmlFor="age">
              <span>{t('questionnaire.question.physical_stats.age')}</span>
              <br />
              <small>{t('questionnaire.question.physical_stats.age_clarification')}</small>
            </label>
            <input
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              id="age"
              onChange={onUpdate('age')}
              value={age || ''}
              autoComplete="off"
            />
          </>
        ) : null}
        <div className="center-button">
          <button type="submit" disabled={disableButton}>
            {t('questionnaire.question.physical_stats.action')}
          </button>
        </div>
      </form>
    </>
  );
}
