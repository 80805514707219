import { APP_BASE } from '../../constants';
import { useScreeningFlow } from 'hooks/useScreeningFlow';
import { useAuth } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';

export default function LogoutButton({ fullWidth = false, marginTop = 0 }) {
  const { logout } = useAuth();
  const { reset } = useScreeningFlow();
  const { t } = useTranslation();

  const onLogout = (e) => {
    e.preventDefault();
    logout();
    reset();
    window.location.replace(`/${APP_BASE}`);
  };

  return (
    <button className={`secondary ${fullWidth ? 'full-width' : ''}`} style={{ marginTop }} onClick={onLogout}>
      {t('start.log_out')}
    </button>
  );
}
