import { useEffect, useRef } from 'react';

type HandlerFunction = () => void;

export default function useClickOutside<T extends HTMLElement>(consumerHandler: HandlerFunction) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const listener = (e) => {
      if (!ref.current?.contains(e.target)) {
        typeof consumerHandler === 'function' && consumerHandler();
      }
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, consumerHandler]);

  return ref;
}
