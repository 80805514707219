import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import SwedishFlagSrc from '../../assets/se.png';
import BritishFlagSrc from '../../assets/gb.png';
import styles from './LanguagePicker.module.scss';
import useDevice from 'hooks/useDevice';
import useClickOutside from 'hooks/useClickOutside';

export default function LanguagePicker() {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useDevice();
  const close = useCallback(() => setIsOpen(false), []);
  const ref = useClickOutside<HTMLDivElement>(close);

  const changeLanguage = (lang: string) => {
    setIsOpen(false);
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  const handleSelect = (e) => {
    const lang = e.target.value;
    changeLanguage(lang);
  };

  return (
    <div className={styles.wrapper}>
      {isMobileOrTablet ? (
        <div className={styles.mobile}>
          <img src={i18n.language === 'sv' ? SwedishFlagSrc : BritishFlagSrc} alt={t(`global.flag.${i18n.language}`)} />
          <select value={i18n.language} onChange={handleSelect}>
            <option value="sv">Svenska</option>
            <option value="en">English</option>
          </select>
        </div>
      ) : (
        <>
          <button className={styles.selectedLanguage} onClick={() => setIsOpen(!isOpen)}>
            <img
              src={i18n.language === 'sv' ? SwedishFlagSrc : BritishFlagSrc}
              alt={t(`global.flag.${i18n.language}`)}
            />
            <span>{i18n.language.toUpperCase()}</span>
            <Chevron />
          </button>
          <div className={`${styles.optionsWrapper} ${isOpen ? styles.open : ''}`} ref={ref}>
            <button className={styles.flagSE} onClick={() => changeLanguage('sv')}>
              Svenska
            </button>
            <button className={styles.flagGB} onClick={() => changeLanguage('en')}>
              English
            </button>
          </div>
        </>
      )}
    </div>
  );
}
