import Mixpanel from 'services/tracking';
import { TEST_ID } from '../constants';
import React, { useState, useEffect } from 'react';
import { basePath } from 'api';

type ContextValue = {
  configuration?: Configuration;
  partnerId?: string;
  partnerName?: string;
  isLoading: boolean;
  error?: unknown;
};

type Configuration = {
  logoImage: string;
  startPage: StartPage;
  mainCta: CallToAction;
  secondaryCta: SecondaryCallToAction;
  lowRisk: LowRisk;
  highRisk: HighRisk;
};

type MultilingualString = {
  sv: string;
  en: string;
};

type HighRisk = {
  heading: MultilingualString;
  text: MultilingualString;
};

type LowRisk = {
  heading?: MultilingualString;
  text?: MultilingualString;
  imageUrl?: MultilingualString;
};

type CallToAction = {
  url: string;
  text: MultilingualString;
};

type SecondaryCallToAction = {
  url: MultilingualString;
  text: MultilingualString;
};

type StartPage = {
  imageUrl: string;
  heading: MultilingualString;
  text: MultilingualString;
};

const ConfigurationContext = React.createContext<ContextValue>({
  configuration: undefined,
  isLoading: false
});

export const ConfigurationProvider = ({ children }) => {
  const [configuration, setConfiguration] = useState<Configuration>();
  const [partnerId, setPartnerId] = useState<string>();
  const [partnerName, setPartnerName] = useState<string>();
  const [isLoading, setIsLoading] = useState(!!TEST_ID);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    if (!TEST_ID) {
      return;
    }

    const abortController = new AbortController();

    setError(undefined);
    setIsLoading(true);
    fetch(`${basePath}/web-screening/${TEST_ID}`, { signal: abortController.signal })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        const error = new Error(`Could not get partner configuration for partner id: "${TEST_ID}"`);
        error.message = res.status.toString();
        throw error;
      })
      .then((json) => {
        setConfiguration(json.partnerWebScreeningConfiguration);
        setPartnerId(json.partnerId);
        setPartnerName(json.partnerName);
        Mixpanel.register({ partnerName: json.partnerName, testId: TEST_ID });
      })
      .catch((err) => {
        console.log(err);
        if (err instanceof Error && err.name !== 'AbortError') {
          // Handle any error not caused by the Abort Controller aborting the request
          if (err.message === '404') {
            window.location.replace('/');
          } else {
            setError(err);
          }
        }
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, []);

  const value = {
    configuration,
    partnerId,
    partnerName,
    isLoading,
    error
  };

  return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
};

export function useConfiguration() {
  return React.useContext(ConfigurationContext);
}
