import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion';
import PhysicalStatsQuestion from 'components/PhysicalStatsQuestion';
import Results from 'components/Results';
import ScreeningAnalysis from 'components/ScreeningAnalysis';
import IntroScreen from 'components/IntroScreen';
import styles from './ScreeningFlow.module.scss';
import AlreadyDiagnosed from 'components/AlreadyDiagnosed';
import { useTranslation } from 'react-i18next';
import { useScreeningFlow } from 'hooks/useScreeningFlow';

export default function ScreeningFlow() {
  const {
    answerQuestion,
    selectAnswerOption,
    goForwards,
    goBack,
    updatePhysicalProperty,
    startTestFlow,
    calculateRisk,
    reset,
    questions,
    currentStep,
    direction,
    multiChoiceSteps,
    disabledQuestion,
    answers,
    weight,
    height,
    age,
    risk
  } = useScreeningFlow();
  const { t } = useTranslation();

  const resetTest = () => {
    reset();
  };

  const getStep = () => {
    if (currentStep === 0) {
      return (
        <Screen key={0} direction={direction} step={currentStep}>
          <IntroScreen onStart={startTestFlow} />
        </Screen>
      );
    }

    if (multiChoiceSteps.includes(currentStep)) {
      return (
        <Screen key={currentStep} direction={direction} step={currentStep}>
          <MultipleChoiceQuestion
            question={questions[currentStep - 1]}
            numQuestions={questions.length + 1}
            answer={answers[questions[currentStep - 1].id]}
            disabled={disabledQuestion === questions[currentStep - 1].id}
            onAnswerQuestion={answerQuestion}
            onSelectAnswer={selectAnswerOption}
          />
        </Screen>
      );
    }

    switch (currentStep) {
      case 6:
        return (
          <Screen key={6} direction={direction} step={currentStep}>
            <PhysicalStatsQuestion
              onNextStep={goForwards}
              onUpdate={updatePhysicalProperty}
              height={height}
              weight={weight}
              age={age}
            />
          </Screen>
        );
      case 7:
        return (
          <Screen key={7} direction={direction} step={currentStep}>
            <ScreeningAnalysis onAnalysisDone={goForwards} delay={3} calculateRisk={calculateRisk} />
          </Screen>
        );
      case 8:
        return (
          <Screen key={8} direction={direction} step={currentStep}>
            <Results risk={risk} bmi={parseInt(weight) / Math.pow(parseInt(height) / 100, 2)} />
          </Screen>
        );
      case 99:
        return (
          <Screen key={99} direction={direction} step={currentStep}>
            <AlreadyDiagnosed />
          </Screen>
        );
      default:
        return <IntroScreen onStart={startTestFlow} />;
    }
  };

  return (
    <div className="wrapper">
      {currentStep > 0 ? (
        <motion.div
          className={styles.controls}
          animate={{ y: 0, height: 'auto' }}
          initial={{ y: -50, height: 0 }}
          transition={{
            y: { delay: 1, duration: 0.3, type: 'spring', stiffness: 500, damping: 30 },
            height: { delay: 0.3 }
          }}
        >
          <div>
            <span>
              {currentStep < 7 ? (
                <button className="text-button" onClick={goBack}>
                  {t('global.go_back')}
                </button>
              ) : currentStep > 7 ? (
                <button className="text-button" onClick={resetTest}>
                  {t('global.redo_test')}
                </button>
              ) : null}
            </span>
            <div className={styles.logo}></div>
          </div>
        </motion.div>
      ) : null}
      <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
        {getStep()}
      </AnimatePresence>
    </div>
  );
}

const Screen = ({ children, direction, step }) => {
  useEffect(() => {
    if (step) {
      window.location.hash = step;
    } else {
      window.history.replaceState(window.history.state, '', window.location.href.split('#')[0]);
    }
  }, [step]);

  const variants = {
    enter: (direction: string) => {
      return {
        x: direction === 'right' ? 400 : -400,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: string) => {
      return {
        zIndex: 0,
        x: direction === 'left' ? 400 : -400,
        opacity: 0
      };
    }
  };
  return (
    <motion.section
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: 'easeInOut' },
        opacity: { duration: 0.2 }
      }}
    >
      {children}
    </motion.section>
  );
};
