import React from 'react';
import { recommendSelf } from 'api';
import { useConfiguration } from './ConfigurationProvider';
import { useAuth } from './AuthProvider';

type ContextValue = {
  recommend(recommendationReason: RecommendationReason): void;
};

export const ApiContext = React.createContext<ContextValue | undefined>(undefined);

export const ApiProvider = ({ children }) => {
  const { session } = useAuth();
  const { partnerId } = useConfiguration();

  const recommend = (recommendationReason: RecommendationReason) => {
    if (!session?.jwt || !session?.patient?.personalIdentityNumber || !partnerId) {
      return;
    }

    const recommendation = {
      personalIdentityNumber: session?.patient?.personalIdentityNumber,
      recommendationReason
    };

    recommendSelf(session.jwt, recommendation, partnerId);
  };

  const value = {
    recommend
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
