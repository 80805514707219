import { useEffect } from 'react';
import styles from './ScreeningAnalysis.module.scss';
import Spinner from 'components/Spinner';
import { useTranslation } from 'react-i18next';

export default function ScreeningAnalysis({ onAnalysisDone, delay, calculateRisk }) {
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(onAnalysisDone, delay * 1000);

    return () => clearTimeout(timeout);
  }, [onAnalysisDone, delay]);

  useEffect(() => {
    calculateRisk();
  }, [calculateRisk]);

  return (
    <div className={styles.analysis}>
      <Spinner />
      <div>{t('questionnaire.analyzing')}</div>
    </div>
  );
}
