import { Fragment, useState } from 'react';
import styles from './MultipleChoiceQuestion.module.scss';
import { useTranslation } from 'react-i18next';
import useDevice from 'hooks/useDevice';

export default function MultipleChoiceQuestion({
  question,
  answer,
  onAnswerQuestion,
  onSelectAnswer,
  disabled,
  numQuestions
}) {
  const { t } = useTranslation();
  const { isMobileOrTablet } = useDevice();
  const [selectedOption, setSelectedOption] = useState(answer);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (typeof selectedOption === 'undefined') {
      return;
    }

    onAnswerQuestion(question.id, selectedOption);
  };

  return (
    <>
      <div className="question-header">
        <small>{t('questionnaire.count', { num: question.id, total: numQuestions })}</small>
        <h2>{t(question.question)}</h2>
      </div>
      {question.clarification ? <p>{t(question.clarification)}</p> : null}
      <form className={disabled ? styles.disabled : undefined} onSubmit={!isMobileOrTablet ? handleSubmit : undefined}>
        {question.options.map((option, i) => (
          <Fragment key={i}>
            {!isMobileOrTablet ? (
              <input
                type="radio"
                value={i}
                name={`question-${question.id}`}
                id={`question-${i}`}
                onChange={() => setSelectedOption(i)}
                checked={selectedOption === i}
              />
            ) : (
              <input
                type="radio"
                value={i}
                name={`question-${question.id}`}
                id={`question-${i}`}
                onClick={answer !== undefined && answer === i ? onSelectAnswer(answer.id) : undefined}
                onChange={onSelectAnswer(question.id)}
                checked={answer !== undefined ? answer === i : false}
              />
            )}
            <label htmlFor={`question-${i}`} className={styles.label} key={`question-${question.id}-${i}`}>
              {t(option.key)}
            </label>
          </Fragment>
        ))}
        {!isMobileOrTablet ? (
          <div className="center-button">
            <button type="submit" disabled={typeof selectedOption === 'undefined'}>
              {t('questionnaire.continue')}
            </button>
          </div>
        ) : null}
      </form>
    </>
  );
}
