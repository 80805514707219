import { useConfiguration } from 'providers/ConfigurationProvider';
import { useTranslation } from 'react-i18next';

type Props = {
  text: string;
  id: string;
  url?: string;
};

const fallbackUrl = 'https://accumbo.page.link/blodtryckstest-web';
const fallbackText = 'Kom igång';

export default function DownloadButton({ text, id, url }: Props) {
  const { configuration } = useConfiguration();
  const { i18n } = useTranslation();
  const linkUrl = url || configuration?.mainCta?.url || fallbackUrl;
  const linkText = text || configuration?.mainCta?.text[i18n.language] || fallbackText;

  return (
    <div className="center-button">
      <a className="button-link full-width" href={linkUrl} target="_blank" id={id} rel="noreferrer">
        {linkText}
      </a>
    </div>
  );
}
