import { useEffect } from 'react';
import Spinner from 'components/Spinner';
import bankIdLogoSrc from '../../../assets/bank-id-large.png';
import styles from './BankIdPrompt.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthProvider';
import Visible from 'components/Visible';

export default function BankIdPrompt({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const { loginState, activeQrCode, cancelLogin } = useAuth();

  useEffect(() => {
    if (loginState === 'authenticated') {
      onSuccess();
    }
  }, [loginState, onSuccess]);

  return (
    <div className={styles.wrapper}>
      <Visible when={loginState === 'starting'}>
        <Spinner size="small" />
      </Visible>
      <Visible when={loginState === 'polling'}>
        {activeQrCode && (
          <div
            dangerouslySetInnerHTML={{
              __html: activeQrCode
            }}
          />
        )}
        <div>
          {t('start.qr_instruction_1')} <strong>{t('start.bankid_scan')}</strong>. {t('start.qr_instruction_2')}
        </div>
      </Visible>
      <Visible when={['starting', 'polling'].includes(loginState)}>
        <button className="text-button" onClick={cancelLogin}>
          {t('start.cancel_login')}
        </button>
      </Visible>
      <Visible when={['user_signing', 'authenticated'].includes(loginState)}>
        <div>
          {t('start.identify_instruction')} <strong>{t('start.bankid_identify')}</strong>.
        </div>
      </Visible>
      <Visible when={loginState === 'waiting_for_bankid'}>
        <img src={bankIdLogoSrc} alt="BankID" />
        <Spinner />
        <h2>{t('start.logging_in')}</h2>
        <p>{t('start.open_bankid')}</p>
      </Visible>
    </div>
  );
}
