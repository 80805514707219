import { useTranslation } from 'react-i18next';
import styles from './Recommendation.module.scss';

export default function Recommendation({ risk, children }) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.wrapper} ${styles[risk]}`}>
      <h3>{t('recommendation.heading')}</h3>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
