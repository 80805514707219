import React from 'react';
import { ApiContext } from 'providers/ApiProvider';

export function useApi() {
  const context = React.useContext(ApiContext);

  if (context === undefined) {
    throw new Error('useApi must be within ApiProvider');
  }

  return context;
}
