import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApiProvider } from 'providers/ApiProvider';
import { ConfigurationProvider } from 'providers/ConfigurationProvider';
import { AuthProvider } from 'providers/AuthProvider';
import './styles/global.scss';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ConfigurationProvider>
      <AuthProvider>
        <ApiProvider>
          <Suspense fallback={<></>}>
            <App />
          </Suspense>
        </ApiProvider>
      </AuthProvider>
    </ConfigurationProvider>
  </React.StrictMode>
);
