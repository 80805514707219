import React, { useReducer, useEffect, useCallback } from 'react';
import reducer, { initialState, ScreeningReducerState } from './screeningFlowReducer';
import Mixpanel from 'services/tracking';
import { useTranslation } from 'react-i18next';
import { isSessionExpired } from 'utils';
import { TEST_ID } from '../constants';
import { useAuth } from './AuthProvider';

type PhysicalProperty = 'weight' | 'height' | 'age';

type ContextValue = ScreeningReducerState & {
  goBack(): void;
  goForwards(toStep?: number): void;
  answerQuestion(questionId: number, optionIndex: number): void;
  selectAnswerOption(questionId: number): void;
  updatePhysicalProperty(property: PhysicalProperty): void;
  calculateRisk(): void;
  startTestFlow(): void;
  reset(message?: string): void;
};

export const ScreeningFlowContext = React.createContext<ContextValue>({
  goBack: () => {},
  goForwards: () => {},
  answerQuestion: () => {},
  selectAnswerOption: () => {},
  updatePhysicalProperty: () => {},
  calculateRisk: () => {},
  startTestFlow: () => {},
  reset: () => {},
  ...initialState
});

export const ScreeningFlowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const { logout, session, setLoginState } = useAuth();

  const hashChangeHandler = useCallback(() => {
    // Step number in the form of #1, #2, #99.
    if (window.location.hash.length > 1 && window.location.hash.length < 4) {
      dispatch({ type: 'setStep', step: parseInt(window.location.hash.replace('#', ''), 10) });
    } else {
      dispatch({ type: 'setStep', step: 0 });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, [hashChangeHandler]);

  const goForwards = (toStep?: number) => {
    dispatch({ type: 'goForwards', toStep: toStep !== undefined && !isNaN(toStep) ? toStep : undefined });
  };

  const goBack = () => {
    window.history.go(-1);
  };

  const trackAnswer = (questionId, optionIndex) => {
    if (state.questions[questionId - 1]) {
      Mixpanel.track('AnswerQuestion', {
        question: state.questions[questionId - 1].question,
        answer: t(state.questions[questionId - 1].options[optionIndex].key)
      });
    }
  };

  const selectAnswerOption = (questionId: number) => (e) => {
    if (TEST_ID && isSessionExpired(session)) {
      logout();
      reset(t('global.session_timed_out'));
      return;
    }

    const optionIndex = parseInt(e.target.value, 10);
    dispatch({ type: 'disableQuestion', questionId });
    dispatch({ type: 'answerQuestion', questionId, optionIndex });
    setTimeout(() => {
      goForwards(state.questions[questionId - 1]?.options[optionIndex]?.nextStep);
      dispatch({ type: 'enableQuestion' });
    }, 400);

    trackAnswer(questionId, optionIndex);
  };

  const answerQuestion = (questionId: number, optionIndex: number) => {
    if (TEST_ID && isSessionExpired(session)) {
      logout();
      reset(t('global.session_timed_out'));
      return;
    }

    dispatch({ type: 'disableQuestion', questionId });
    dispatch({ type: 'answerQuestion', questionId, optionIndex });
    goForwards(state.questions[questionId - 1]?.options[optionIndex]?.nextStep);
    dispatch({ type: 'enableQuestion' });

    trackAnswer(questionId, optionIndex);
  };

  const updatePhysicalProperty = (property: PhysicalProperty) => (e) =>
    dispatch({ type: `setPhysicalProperty`, property, value: e.target.value });

  const calculateRisk = useCallback(
    () => dispatch({ type: 'calculateRisk', personalNumber: session?.patient?.personalIdentityNumber }),
    [dispatch, session?.patient?.personalIdentityNumber]
  );

  const startTestFlow = useCallback(() => {
    Mixpanel.track('StartTest');
    Mixpanel.timeEvent('QuestionnaireCompleted');
    goForwards();

    setTimeout(() => {
      // Allows navigating back to the first page
      // Run in a timeout to wait for animation to the first question to complete
      setLoginState('not_started');
    }, 500);
  }, [setLoginState]);

  const reset = (message = '') => dispatch({ type: 'reset', message });

  const value = {
    goBack,
    goForwards,
    answerQuestion,
    selectAnswerOption,
    updatePhysicalProperty,
    calculateRisk,
    startTestFlow,
    reset,
    questions: state.questions,
    answers: state.answers,
    currentStep: state.currentStep,
    multiChoiceSteps: state.multiChoiceSteps,
    direction: state.direction,
    disabledQuestion: state.disabledQuestion,
    weight: state.weight,
    height: state.height,
    age: state.age,
    risk: state.risk,
    resetMessage: state.resetMessage
  };

  return <ScreeningFlowContext.Provider value={value}>{children}</ScreeningFlowContext.Provider>;
};
