import mixpanel from 'mixpanel-browser';

let _trackingEnabled = process.env.NODE_ENV === 'production';
let mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
let isInitialized = false;

type JsonValue = string | number | boolean | null | JsonValue[] | { [key: string]: JsonValue };

export type TrackingEvent =
  | 'StartTest'
  | 'Screen'
  | 'AnswerQuestion'
  | 'QuestionnaireCompleted'
  | 'ShareButtonClicked'
  | 'AlreadyDiagnosedDownloadButton1Clicked'
  | 'AlreadyDiagnosedDownloadButton2Clicked'
  | 'HighRiskAgeDownloadButton1Clicked'
  | 'HighRiskAgeDownloadButton2Clicked'
  | 'HighRiskGenericDownloadButton1Clicked'
  | 'HighRiskGenericDownloadButton2Clicked';

const Mixpanel = {
  init: (superProperties?: Record<string, JsonValue>) => {
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, { api_host: 'https://api-eu.mixpanel.com', ip: false }, '');
      mixpanel.set_config({
        track_links_timeout: 2000
      });
      mixpanel.register(
        superProperties || {
          partnerName: null,
          testId: null
        }
      );
      isInitialized = true;
    }
  },
  trackingEnabled: (isEnabled: boolean) => {
    _trackingEnabled = isEnabled;
  },
  identify: (id?: string) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.identify(id);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Identify', id);
    }
  },
  setProperty: (property: string, value: any) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.people.set(property, value);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Setting user property', property, value);
    }
  },
  setProperties: (properties: Record<string, JsonValue>) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.people.set(properties);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Setting user properties', properties);
    }
  },
  track: (name: TrackingEvent, props = {}) => {
    const trackingProps = { ...props };
    if (_trackingEnabled && isInitialized) {
      mixpanel.track(name, trackingProps);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Tracking event', name, trackingProps);
    }
  },
  trackLinks: (selector: string, name: TrackingEvent) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.track_links(selector, name);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Tracking links', selector, name);
    }
  },
  trackWithBeacon: (name: TrackingEvent, props?: JsonValue) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.track(name, props, { transport: 'sendBeacon' });
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Tracking event', name, props);
    }
  },
  timeEvent: (name: TrackingEvent) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.time_event(name);
    } else if (process.env.NODE_ENV !== 'production') {
      console.log('Timing event', name);
    }
  },
  register: (superProperties) => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.register(superProperties);
    } else {
      console.log('Registering super properties', superProperties);
    }
  },
  resetSuperProperties: () => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.register({
        partnerName: null,
        testId: null
      });
    } else {
      console.log('Resetting super properties');
    }
  },
  reset: () => {
    if (_trackingEnabled && isInitialized) {
      mixpanel.reset();
    }
  }
};

export default Mixpanel;
