import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IntroScreen.module.scss';
import mainImageSrc from '../../assets/start-screen.jpg';
import bankIdLogoSrc from '../../assets/bank-id-small.png';
import Disclaimer from 'components/Disclaimer';
import useDevice from 'hooks/useDevice';
import LanguagePicker from 'components/LanguagePicker';
import Visible from 'components/Visible';
import BankIdPrompt from './BankIdPrompt';
import { isSessionExpired } from 'utils';
import LogoutButton from 'components/LogoutButton';
import { useScreeningFlow } from 'hooks/useScreeningFlow';
import { useConfiguration } from 'providers/ConfigurationProvider';
import SkeletonComponent from 'components/SkeletonComponent';
import RenderNewLines from 'components/RenderNewLines';
import { TEST_ID } from '../../constants';
import { useAuth } from 'providers/AuthProvider';

export default function IntroScreen({ onStart }) {
  const isDevMode = process.env.REACT_APP_ENV !== 'production';
  const [identifier, setIdentifier] = useState('');
  const { session, loginWithCredentials, completeLogin, loginState, setLoginState, loginOnDevice, beginQrLoginFlow } =
    useAuth();
  const { resetMessage } = useScreeningFlow();
  const { t, i18n } = useTranslation();
  const device = useDevice();
  const { configuration, isLoading: isLoadingConfiguration, error: configurationError } = useConfiguration();

  useEffect(() => {
    const img = new Image();
    img.src = bankIdLogoSrc;
  }, []);

  useEffect(() => {
    const visibilityHandler = () => {
      if (document.visibilityState === 'visible') {
        const storedRefToken = localStorage.getItem('refToken');

        if (storedRefToken) {
          completeLogin(storedRefToken).then(onStart);
        }
      }
    };

    document.addEventListener('visibilitychange', visibilityHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
    };
  }, [completeLogin, onStart]);

  const loginDev = async (e) => {
    e.preventDefault();

    try {
      await loginWithCredentials(identifier, 'testpwd');
      onStart();
    } catch (e) {
      console.log(e);
    }
  };

  const beginLoginFlow = () => {
    if (!isSessionExpired(session)) {
      onStart();
    } else {
      window.scrollTo(0, 0);
      setLoginState('idle');
    }
  };

  if (isLoadingConfiguration) {
    return (
      <>
        <SkeletonComponent height="30px" margin="20px" />
        <SkeletonComponent height="300px" margin="20px" />
        <SkeletonComponent height="20px" margin="20px" />
        <SkeletonComponent height="200px" margin="20px" />
        <SkeletonComponent height="200px" margin="20px" />
      </>
    );
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}></div>
        {TEST_ID && configuration?.logoImage ? (
          <div className={styles.partner}>
            <span>{t('start.in_collaboration_with')}</span>
            <img src={configuration.logoImage} alt="Logo" />
          </div>
        ) : null}
      </header>
      <div className="stack">
        <Visible when={['not_started', 'idle', 'enter_email', 'error'].includes(loginState)}>
          <LanguagePicker />
          <div className="mt-30">
            <img
              src={TEST_ID ? configuration?.startPage?.imageUrl || mainImageSrc : mainImageSrc}
              alt="Blodtryckstest"
            />
          </div>
        </Visible>
        <Visible when={loginState === 'not_started'}>
          {TEST_ID ? (
            <>
              {configuration?.startPage?.heading?.sv && <h2>{configuration?.startPage?.heading[i18n.language]}</h2>}
              {configuration?.startPage?.text?.sv && (
                <div>
                  <p>
                    <RenderNewLines text={configuration?.startPage?.text[i18n.language]} />
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <h2>{t('start.heading')}</h2>
              <div>
                <p>{t('start.paragraph_1')}</p>
                <p>{t('start.paragraph_2')}</p>
              </div>
            </>
          )}
          <div className="stack">
            <div>
              <Visible when={!configurationError}>
                <button className="full-width" onClick={TEST_ID ? beginLoginFlow : onStart}>
                  {session?.patient?.personalIdentityNumber && !isSessionExpired(session)
                    ? t('start.continue_as', { name: `${session.patient.givenName} ${session.patient.familyName}` })
                    : t('start.begin_login')}
                  {TEST_ID ? <img src={bankIdLogoSrc} alt="Bank-ID" /> : null}
                </button>
                {resetMessage ? (
                  <div className="button-explainer">
                    <small>{resetMessage}</small>
                  </div>
                ) : null}
              </Visible>
              <Visible when={configurationError}>
                <div className={styles.genericError}>{t('start.config_error')}</div>
              </Visible>
            </div>
            {session?.jwt && !isSessionExpired(session) ? <LogoutButton fullWidth /> : null}
          </div>
        </Visible>
        <Visible when={loginState === 'idle'}>
          <Visible when={device.isMobileOrTablet}>
            <button className="full-width" onClick={loginOnDevice}>
              {t('start.open_bankid_on_this_device')}
              <img src={bankIdLogoSrc} alt="Bank-ID" />
            </button>
            <button className="full-width secondary" onClick={beginQrLoginFlow}>
              {t('start.log_in_on_other_device')}
              <img src={bankIdLogoSrc} alt="Bank-ID" />
            </button>
          </Visible>
          <Visible when={!device.isMobileOrTablet}>
            <button className="full-width" onClick={beginQrLoginFlow}>
              {t('start.log_in_mobile_bankid')}
              <img src={bankIdLogoSrc} alt="Bank-ID" />
            </button>
          </Visible>
          <Visible when={isDevMode}>
            <button className="full-width text-button" onClick={() => setLoginState('enter_email')}>
              {t('start.log_in_dev_heading')}
            </button>
          </Visible>
        </Visible>
        <Visible when={loginState === 'enter_email'}>
          <form onSubmit={loginDev}>
            <div>
              <label htmlFor="auth-input">{t('start.input_label_dev')}</label>
              <input
                autoFocus
                type="text"
                inputMode="numeric"
                id="auth-input"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                required
                autoComplete="off"
              />
            </div>
            <button className="full-width" type="submit">
              {t('start.log_in_dev')}
              <img src={bankIdLogoSrc} alt="Bank-ID" />
            </button>
          </form>
        </Visible>
        <Visible
          when={['waiting_for_bankid', 'starting', 'polling', 'user_signing', 'authenticated'].includes(loginState)}
        >
          <BankIdPrompt onSuccess={onStart} />
        </Visible>
        <Visible when={loginState === 'error'}>
          <div className="error-message" style={{ marginBottom: '1em', textAlign: 'center', fontSize: '14px' }}>
            {t('start.login_error')}
          </div>
          <button className="secondary full-width" onClick={() => setLoginState('idle')}>
            {t('start.try_again')}
          </button>
        </Visible>
        <Disclaimer />
        <p className={styles.privacyPolicy}>
          <a href="https://blodtrycksdoktorn.se/integritetspolicy/" target="_blank" rel="noreferrer noopener">
            {t('start.privacy_policy_link')}
          </a>
        </p>
      </div>
    </>
  );
}
