import { useTranslation } from 'react-i18next';
import styles from './Disclaimer.module.scss';

export default function Disclaimer() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h5>{t('disclaimer.heading')}</h5>
      {t('disclaimer.body')}
    </div>
  );
}
