import isMobileLib from 'is-mobile';

const useDevice = () => {
  const isMobileOrTablet = isMobileLib({ tablet: true, featureDetect: true });
  const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/));
  const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/));
  const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/));
  const isAppleDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  return {
    isMobileOrTablet,
    isChromeOnAppleDevice,
    isFirefoxOnAppleDevice,
    isOperaTouchOnAppleDevice,
    isAppleDevice
  };
};

export default useDevice;
