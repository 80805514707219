import { useEffect } from 'react';
import styles from './Results.module.scss';
import Mixpanel from 'services/tracking';
import LowRisk from './LowRisk';
import HighRisk from './HighRisk';
import { AGE_THRESHOLD, TEST_ID } from '../../constants';
import { getAgeFromPersonalNumber } from 'utils';
import { useScreeningFlow } from 'hooks/useScreeningFlow';
import { useAuth } from 'providers/AuthProvider';

export default function Results({ risk, bmi }) {
  const { session } = useAuth();
  const { age } = useScreeningFlow();
  const userAge = getAgeFromPersonalNumber(session?.patient?.personalIdentityNumber) || Number(age);

  useEffect(() => {
    if (bmi) {
      Mixpanel.track('QuestionnaireCompleted');
      Mixpanel.track('Screen', {
        name: 'Results',
        risk,
        bmi: parseFloat(bmi.toPrecision(3)),
        age
      });
    }
    Mixpanel.trackLinks('#download-1', 'HighRiskGenericDownloadButton1Clicked');
    Mixpanel.trackLinks('#download-2', 'HighRiskGenericDownloadButton2Clicked');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(`${TEST_ID}-result`, risk);
  }, [risk]);

  const style = {
    '--dialAngle': `${risk === 'low' && userAge >= AGE_THRESHOLD ? 85 : risk === 'low' ? 32 : 127}deg`
  } as React.CSSProperties;

  return (
    <>
      <div className={styles.results}>
        <div className={styles.diagram}>
          <span className={styles.dial} style={style}></span>
        </div>
      </div>
      {risk === 'low' ? <LowRisk /> : <HighRisk />}
    </>
  );
}
