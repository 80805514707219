import { getAgeFromPersonalNumber } from 'utils';
import { BMI_THRESHOLD, TEST_ID, HEREDITY_AGE_THRESHOLD } from '../constants';

export type ScreeningReducerState = {
  questions: Question[];
  answers: Record<number, number>;
  currentStep: number;
  multiChoiceSteps: number[];
  height: string;
  weight: string;
  age: string;
  disabledQuestion?: number;
  risk: Risk;
  direction: 'right' | 'left';
  resetMessage?: string;
};

export type Question = {
  id: number;
  question: string;
  clarification?: string;
  options: Option[];
};

type Option = {
  key: string;
  nextStep?: number;
};

type ScreeningAction =
  | { type: 'answerQuestion'; questionId: number; optionIndex: number }
  | { type: 'disableQuestion'; questionId: number }
  | { type: 'enableQuestion' }
  | { type: 'goBack' }
  | { type: 'goForwards'; toStep?: number }
  | { type: 'setPhysicalProperty'; property: string; value: number }
  | { type: 'setStep'; step: number }
  | { type: 'calculateRisk'; personalNumber?: string }
  | { type: 'reset'; message?: string };

export const initialState: ScreeningReducerState = {
  questions: [
    {
      id: 1,
      question: 'questionnaire.question.diagnosis.text',
      clarification: 'questionnaire.question.diagnosis.clarification',
      options: [
        {
          key: 'questionnaire.answer.yes',
          nextStep: 99
        },
        {
          key: 'questionnaire.answer.no'
        },
        {
          key: 'questionnaire.answer.do_not_know'
        }
      ]
    },
    {
      id: 2,
      question: 'questionnaire.question.measured_high.text',
      clarification: 'questionnaire.question.measured_high.clarification',
      options: [
        {
          key: 'questionnaire.answer.yes'
        },
        {
          key: 'questionnaire.answer.no'
        }
      ]
    },
    {
      id: 3,
      question: 'questionnaire.question.stroke.text',
      clarification: '',
      options: [
        {
          key: 'questionnaire.answer.yes'
        },
        {
          key: 'questionnaire.answer.no'
        }
      ]
    },
    {
      id: 4,
      question: 'questionnaire.question.diabetes.text',
      clarification: '',
      options: [
        {
          key: 'questionnaire.answer.yes'
        },
        {
          key: 'questionnaire.answer.no'
        }
      ]
    },
    {
      id: 5,
      question: 'questionnaire.question.heredity.text',
      clarification: '',
      options: [
        {
          key: 'questionnaire.answer.yes'
        },
        {
          key: 'questionnaire.answer.no'
        }
      ]
    }
  ],
  currentStep: 0,
  multiChoiceSteps: [1, 2, 3, 4, 5],
  answers: {},
  height: '',
  weight: '',
  age: '',
  risk: 'high',
  direction: 'right',
  resetMessage: ''
};

const storedResult = localStorage.getItem(`${TEST_ID}-result`);
if (storedResult) {
  initialState.currentStep = 8;
  initialState.risk = storedResult as Risk;
}

const calculateRisk = (height: number, weight: number, age: number, answers: Record<number, number>) => {
  const answerCopy = { ...answers };
  delete answerCopy[5]; // The heredity question is handled separately

  const hasHeredityRisk = answers[5] === 0 && age >= HEREDITY_AGE_THRESHOLD;
  const hasAnsweredAnyYes = Object.values(answerCopy).includes(0);
  const bmi = weight / Math.pow(height / 100, 2);
  return hasHeredityRisk || hasAnsweredAnyYes || bmi >= BMI_THRESHOLD ? 'high' : 'low';
};

const reducer = (state: ScreeningReducerState = initialState, action: ScreeningAction): ScreeningReducerState => {
  switch (action.type) {
    case 'answerQuestion':
      return {
        ...state,
        answers: { ...state.answers, [action.questionId]: action.optionIndex }
      };
    case 'goBack':
      return { ...state, currentStep: state.currentStep - 1, direction: 'left' };
    case 'goForwards':
      return { ...state, currentStep: action.toStep || state.currentStep + 1, direction: 'right', resetMessage: '' };
    case 'setStep':
      return { ...state, currentStep: action.step, direction: action.step > state.currentStep ? 'right' : 'left' };
    case 'setPhysicalProperty':
      return { ...state, [action.property]: action.value };
    case 'disableQuestion':
      return { ...state, disabledQuestion: action.questionId };
    case 'enableQuestion':
      return { ...state, disabledQuestion: undefined };
    case 'calculateRisk': {
      const age = getAgeFromPersonalNumber(action.personalNumber) || state.age;

      if (!age) {
        throw new Error('Could not calculate age');
      }

      return {
        ...state,
        age: age.toString(),
        risk: calculateRisk(Number(state.height), Number(state.weight), Number(age), state.answers)
      };
    }
    case 'reset': {
      localStorage.removeItem(`${TEST_ID}-result`);
      window.location.hash = '';
      const newState: ScreeningReducerState = {
        ...initialState,
        currentStep: 0,
        direction: 'left',
        resetMessage: state.resetMessage
      };

      if (typeof action.message !== 'undefined') {
        newState.resetMessage = action.message;
      }

      return newState;
    }
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export default reducer;
