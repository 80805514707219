import environments from '../environments.json';

export const basePath = environments[process.env.REACT_APP_ENV || 'development']?.apiUrl;

export const challenge = async (): Promise<Response> => {
  const challengeRequest = {
    method: 'bankid'
  };

  return fetch(`${basePath}/partner/auth/patientchallenge`, {
    method: 'POST',
    body: JSON.stringify(challengeRequest),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const completeLogin = async (challengeToken: string): Promise<Response> => {
  const loginRequest = {
    challengeToken,
    method: 'bankid'
  };

  return fetch(`${basePath}/partner/auth/patientlogin`, {
    method: 'POST',
    body: JSON.stringify(loginRequest),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const loginWithCredentials = async (email?: string, password?: string): Promise<Response> => {
  const credentials = { identifier: email, password, method: 'credentials' };

  return fetch(`${basePath}/partner/auth/patientlogin`, {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const recommendSelf = async (
  jwt: string,
  recommendation: Recommendation,
  partnerId: string
): Promise<Response> => {
  return fetch(`${basePath}/partner/selfrecommendation?partnerId=${partnerId}`, {
    method: 'POST',
    body: JSON.stringify(recommendation),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  });
};

export const beginLoginFlow = async (): Promise<SecureStartResponse> => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${basePath}/auth/login/qr`, {
      method: 'POST'
    });

    if (!res.ok) {
      reject(res);
    } else {
      const json = (await res.json()) as SecureStartResponse;
      resolve(json);
    }
  });
};

export const refreshQrCode = async (sessionId: string, signal?: AbortSignal): Promise<BankIdPollingResponse> => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${basePath}/auth/partner/patient/login/qr/${sessionId}`, { signal });

    if (!res.ok) {
      reject(res);
    } else {
      const json = (await res.json()) as BankIdPollingResponse;
      resolve(json);
    }
  });
};
