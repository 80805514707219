import { isAfter, differenceInYears } from 'date-fns';

export const isSessionExpired = (session?: LoginResponse) => {
  if (!session) {
    return true;
  }

  const now = new Date();
  const expires = new Date(session.expires);

  return isAfter(now, expires);
};

export const getAgeFromPersonalNumber = (personalNumber?: string) => {
  if (!personalNumber) return;
  const year = Number(personalNumber.substring(0, 4));
  const month = Number(personalNumber.substring(4, 6));
  const day = Number(personalNumber.substring(6, 8));

  return differenceInYears(new Date(), new Date(year, month, day));
};
