import Recommendation from 'components/Recommendation';
import Disclaimer from 'components/Disclaimer';
import { useTranslation } from 'react-i18next';
import DownloadButton from 'components/DownloadButton';
import LogoutButton from 'components/LogoutButton';
import { getAgeFromPersonalNumber } from 'utils';
import { AGE_THRESHOLD, TEST_ID } from '../../../constants';
import { useConfiguration } from 'providers/ConfigurationProvider';
import RenderNewLines from 'components/RenderNewLines';
import { useScreeningFlow } from 'hooks/useScreeningFlow';
import bpImageSv from 'assets/images/bp-station.png';
import bpImageEn from 'assets/images/bp-station-en.png';
import measurementImage from 'assets/images/matning.jpg';
import StoreRating from 'components/StoreRating';
import { useAuth } from 'providers/AuthProvider';

export default function LowRisk() {
  const { t, i18n } = useTranslation();
  const { session } = useAuth();
  const { configuration } = useConfiguration();
  const { age } = useScreeningFlow();
  const userAge = getAgeFromPersonalNumber(session?.patient?.personalIdentityNumber) || Number(age);
  const ageSuffix = userAge >= AGE_THRESHOLD ? '_age' : '';

  return (
    <>
      <h2>{t(`result_low.heading${ageSuffix}`)}</h2>
      <Recommendation risk="low">{t(`recommendation.low${ageSuffix}`)}</Recommendation>
      {userAge >= AGE_THRESHOLD ? (
        <>
          <h2>{t('result_low.heading_age')}</h2>
          <p>{t('result_low.paragraph_age')}</p>
        </>
      ) : null}
      {TEST_ID ? (
        <>
          {configuration?.lowRisk?.heading?.sv && <h2>{configuration.lowRisk.heading[i18n.language]}</h2>}
          {configuration?.lowRisk?.text?.sv && (
            <p>
              <RenderNewLines text={configuration.lowRisk.text[i18n.language]} />
            </p>
          )}
          {configuration?.secondaryCta?.text?.sv && (
            <p>
              <DownloadButton
                text={configuration?.secondaryCta.text[i18n.language]}
                id="low-cta"
                url={configuration?.secondaryCta.url[i18n.language]}
              />
            </p>
          )}
          {configuration?.lowRisk?.imageUrl?.sv && (
            <p>
              <img src={configuration.lowRisk.imageUrl[i18n.language]} alt={t('global.measurement_station')} />
            </p>
          )}
        </>
      ) : (
        <>
          {userAge < AGE_THRESHOLD ? (
            <>
              <h2>{t('result_low.sub_heading')}</h2>
              <p>{t('result_low.paragraph')}</p>
              <p>
                <DownloadButton
                  text={t('result_low.call_to_action')}
                  id="low-cta"
                  url="https://blodtrycksdoktorn.se/hitta-apotek/"
                />
              </p>
              <p>
                <img src={i18n.language === 'sv' ? bpImageSv : bpImageEn} alt={t('global.measurement_station')} />
              </p>
            </>
          ) : (
            <>
              <p>
                <DownloadButton text={t('global.get_started')} id="download-1" />
                <div className="button-explainer margin-offset">
                  <small>{t('disclaimer.download')}</small>
                </div>
              </p>

              <p>{t('result_high_generic.paragraph_2_3')}</p>
              <p>
                <img src={measurementImage} alt={t('result_high_generic.measurement_image_alt')} />
              </p>
              <h2>{t('result_high_generic.sub_heading_3')}</h2>
              <ul>
                <li>{t('result_high_generic.item_1')}</li>
                <li>{t('result_high_generic.item_2')}</li>
                <li>{t('result_high_generic.item_3')}</li>
              </ul>
              <h2>{t('result_high_generic.sub_heading_4')}</h2>
              <p>{t('result_high_generic.paragraph_4_1')}</p>
              <p>{t('result_high_generic.paragraph_4_2')}</p>
              <p>{t('result_high_generic.paragraph_4_3')}</p>
              <ul>
                <li>{t('result_high_generic.item_4')}</li>
              </ul>
              <DownloadButton text={t('global.download_app')} id="download-2" />
              <StoreRating />
            </>
          )}
        </>
      )}
      {session ? <LogoutButton marginTop={20} /> : null}
      <Disclaimer />
    </>
  );
}
