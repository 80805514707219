import React, { useState, useCallback, useRef } from 'react';
import {
  challenge as challengeApi,
  completeLogin as completeLoginApi,
  loginWithCredentials as loginWithCredentialsApi,
  refreshQrCode,
  beginLoginFlow as apiBeginLoginFlow
} from 'api';
import { TEST_ID } from '../constants';
import { isSessionExpired } from 'utils';
import useDevice from 'hooks/useDevice';

const POLL_RATE_IN_MS = 1000;

const getTimeUntilNextRequest = (requestTime: number) => {
  return POLL_RATE_IN_MS - requestTime > 0 ? POLL_RATE_IN_MS - requestTime : 0;
};

type LoginState =
  | 'not_started'
  | 'idle'
  | 'enter_email'
  | 'waiting_for_bankid'
  | 'starting'
  | 'polling'
  | 'user_signing'
  | 'authenticated'
  | 'error';

type ContextValue = {
  session?: LoginResponse;
  challenge(identifier: string): Promise<ChallengeResponse>;
  completeLogin(refToken: string): Promise<void>;
  loginWithCredentials(identifier: string, password: string): Promise<void>;
  loginOnDevice(): Promise<void>;
  logout(): void;
  beginQrLoginFlow(): Promise<void>;
  cancelLogin: () => void;
  setLoginState: (newState: LoginState) => void;
  loginState: LoginState;
  activeQrCode?: Base64SvgImage;
  error?: unknown;
};

const AuthContext = React.createContext<ContextValue | undefined>(undefined);

const storageKey = `${TEST_ID}-auth`;

let initialSession: LoginResponse;
const storedSession = localStorage.getItem(storageKey);
if (storedSession) {
  const storedJson = JSON.parse(storedSession);
  if (!isSessionExpired(storedJson)) {
    initialSession = storedJson;
  } else {
    localStorage.removeItem(storageKey);
  }
}

export const AuthProvider = ({ children }) => {
  const [session, setSessionRaw] = useState<LoginResponse | undefined>(initialSession);
  const [activeQrCode, setActiveQrCode] = useState<Base64SvgImage>();
  const [loginState, _setLoginState] = useState<LoginState>('not_started');
  const loginStateRef = useRef<LoginState>('idle');
  const [error, _setError] = useState<unknown>();
  const device = useDevice();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const abortControllerRef = useRef<AbortController>();

  const beginQrLoginFlow = async () => {
    setLoginState('starting');

    try {
      const start = new Date();
      const response = await apiBeginLoginFlow();
      const finish = new Date();
      const requestDuration = finish.valueOf() - start.valueOf();
      setActiveQrCode(window.atob(response.QRCode));
      setLoginState('polling');
      timeoutRef.current = setTimeout(() => {
        updateQrCode(response.sessionId);
      }, getTimeUntilNextRequest(requestDuration));
    } catch (err) {
      setError(err);
    }
  };

  const updateQrCode = async (sessionId: string) => {
    try {
      abortControllerRef.current = new AbortController();
      const start = new Date();
      const response = await refreshQrCode(sessionId, abortControllerRef.current.signal);
      const finish = new Date();
      const requestDuration = finish.valueOf() - start.valueOf();
      const status = response.sessionResponse?.grandidObject?.message?.status;
      const hintCode = response.sessionResponse?.grandidObject?.message?.hintCode;

      if (hintCode === 'userSign') {
        setLoginState('user_signing');
      }

      if (status === 'failed') {
        if (hintCode === 'userCancel') {
          setLoginState('idle');
        } else {
          setError('Login failed');
        }
      }

      if (status === 'succeeded' && response.loginResponse) {
        setSession(response.loginResponse);
        setLoginState('authenticated');
        return;
      }

      setActiveQrCode(window.atob(response.sessionResponse.grandidObject.QRCode));

      if (['polling', 'user_signing'].includes(loginStateRef.current)) {
        timeoutRef.current = setTimeout(() => {
          updateQrCode(sessionId);
        }, getTimeUntilNextRequest(requestDuration));
      }
    } catch (err) {
      setError(err);
      // @ts-ignore
      clearTimeout(timeoutRef.current);
    }
  };

  const cancelLogin = () => {
    setLoginState('idle');
    setActiveQrCode(undefined);
    clearError();
    // @ts-ignore
    clearTimeout(timeoutRef.current);
    abortControllerRef.current?.abort();
  };

  const setError = useCallback((err: unknown) => {
    _setError(err);
    setLoginState('error');
  }, []);

  const clearError = () => {
    _setError(undefined);
    setLoginState('idle');
  };

  const setLoginState = (newState: LoginState) => {
    _setLoginState(newState);
    loginStateRef.current = newState;
  };

  const getRedirectBase = () => {
    if (device.isChromeOnAppleDevice) {
      return window.encodeURIComponent('googlechrome://');
    }

    if (device.isFirefoxOnAppleDevice) {
      return window.encodeURIComponent('firefox://');
    }

    if (device.isOperaTouchOnAppleDevice) {
      return window.encodeURIComponent(`${window.location.href.replace('http', 'touch-http')}#initiated=true`);
    }

    if (device.isAppleDevice) {
      return encodeURIComponent(`${window.location.href}#initiated=true`);
    }

    return 'null';
  };

  const getRedirectUrl = (payload: string) => {
    return payload
      .replace('bankid:///', 'https://app.bankid.com/')
      .replace('redirect=null', `redirect=${getRedirectBase()}`);
  };

  const loginOnDevice = async () => {
    try {
      setLoginState('waiting_for_bankid');

      const challengeResponse = await challenge();

      const newUrl = getRedirectUrl(challengeResponse.payload);
      window.location.replace(newUrl);
    } catch (e) {
      setError(e);
    }
  };

  const challenge = async (): Promise<ChallengeResponse> => {
    const challengeResponse = await challengeApi();

    if (!challengeResponse.ok) {
      throw new Error('Challenge failed');
    }

    const challengeJson: ChallengeResponse = await challengeResponse.json();

    localStorage.setItem('refToken', challengeJson.refToken);

    if (!challengeJson.refToken) {
      throw new Error('RefToken missing');
    }

    return challengeJson;
  };

  const completeLogin = useCallback(
    async (refToken: string) => {
      const loginResponse = await completeLoginApi(refToken);

      localStorage.removeItem('refToken');

      if (!loginResponse.ok) {
        setError('Login failed');
        throw new Error();
      }

      const loginResponseJson: LoginResponse = await loginResponse.json();

      setSession(loginResponseJson);
      setLoginState('authenticated');
    },
    [setError]
  );

  const loginWithCredentials = async (identifier: string, password: string) => {
    setLoginState('starting');

    const credentialsResponse = await loginWithCredentialsApi(identifier, password);

    if (!credentialsResponse.ok) {
      setError('Credentials login failed');
      throw new Error();
    }

    const loginResponseJson: LoginResponse = await credentialsResponse.json();

    setSession(loginResponseJson);
  };

  const setSession = (loginResponse: LoginResponse) => {
    setSessionRaw(loginResponse);
    localStorage.setItem(storageKey, JSON.stringify(loginResponse));
  };

  const logout = () => {
    setSessionRaw(undefined);
    localStorage.removeItem(storageKey);
  };

  const value = {
    session,
    loginState,
    activeQrCode,
    error,
    challenge,
    completeLogin,
    loginWithCredentials,
    loginOnDevice,
    logout,
    beginQrLoginFlow,
    cancelLogin,
    setLoginState
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth can only be used inside an AuthProvider');
  }

  return context;
}
